import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import qrcode from "../images/qrcode.jpg";
import { Card, Row, Col, Button } from "react-bootstrap";
const Payment = () => {

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Payment Details </h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>

                <Form>
                    {/* <Form.Check  // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Payment mode"
                        /> */}
                    <Card body>
                        <Card title=""></Card>
                        <Card body id="card">
                            <Row>
                                <Col lg={3}></Col>
                                <Col lg={6}>

                                    <Row>
                                        <Col lg={12} >
                                            <Form.Group>
                                                <Form.Label className="stud">Application No</Form.Label>
                                                <Form.Control type="text" className="amount" name="amount" size="sm"
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={12} >
                                            <Form.Group>
                                                <Form.Label className="stud">Amount</Form.Label>
                                                <Form.Control type="text" className="amount" name="amount" size="sm"
                                                />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="Submit" variant="primary" size="sm" style={{ float: "right", marginTop: 15 }}>Payment Now</Button>
                                </Col>
                                <Col lg={3}>
                                </Col>
                            </Row>

                        </Card>
                    </Card>

                </Form>

            </main >
            <Footer />
        </>
    )
}

export default Payment;