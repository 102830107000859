import { Link } from "react-router-dom/cjs/react-router-dom.min"
import React from "react";
import { useEffect, useState } from "react";
import setting from "../setting.json";

const Sidebar = () => {
    // const [list, setList] = useState([]);

    // const getRole = async () => {
    //     await fetch(setting.api + '/api/getRole', {
    //         method: "GET",
    //         mode: "cors",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
    //         },

    //     })
    //         .then(response => response.json())
    //         .then(dd => {
    //             setList(dd.data);
    //         }
    //         );
    // }

    // useEffect(() => {
    //     getRole();
    // }, []);

    return (

        < aside id="sidebar" className="sidebar" >
            <ul className="sidebar-nav">
                <li className="nav-item">
                    <a className="nav-link " href="/">
                        <i className="bi bi-grid"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
            </ul>


            {/* // list.role == 1 ? */}
            <ul className="sidebar-nav">
                {/* < li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#master-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"></i><span>Master</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="master-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">


                        <li className="nav-item">
                            <a className="nav-link collapsed" href="/class">
                                <i className="bi bi-grid"></i>
                                <span>Class</span>
                            </a>
                        </li>
                    </ul>
                </li> */}

                <li className="nav-item">
                    <a className="nav-link collapsed" href="/examnation">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Examnation</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/comlainlist">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Complaints</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/feedback">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Feedback</span>
                    </a>
                </li>

                <li className="nav-item">
                    <a className="nav-link collapsed" href="/contact">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Contact</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/notification">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Notification</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/admission">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Admission</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/studentlist">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Student List</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/applicationdetails">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Student Application Details</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="/message">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Message</span>
                    </a>
                </li>

                <li className="nav-item">
                    <a className="nav-link collapsed" href="/payment">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>Payment</span>
                    </a>
                </li>

            </ul>
            : ' '



        </aside >

    );
}
export default Sidebar;