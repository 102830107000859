import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Table } from "react-bootstrap";

const ApplicationDetails = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>Date of Admission</th>
                            <th>Application Number</th>
                            <th>Students Name</th>
                            <th>Fathers Name</th>
                            <th>Mobile Number</th>
                            <th>Class/ Semester</th>
                            <th>Paid Amount</th>
                            <th>Mode of payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                            <td>Table cell</td>
                        </tr>
                    </tbody>
                </Table>
            </main>
            <Footer />
        </>
    )
}
export default ApplicationDetails;