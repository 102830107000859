import './App.css';

import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import School from './pages/School';
import Feedback from './pages/Feedback';
import Examnation from './pages/Examnation';
import Contact from './pages/Contact';
import ComplainList from './pages/ComplainList';
import Notification from './pages/Notification';
import Admission from './pages/Admission';
import StudentList from './pages/StudentList';
import ApplicationDetails from './pages/ApplicationDetails';
import Message from './pages/Message';
import Payment from './pages/Payment';
import Payqr from './pages/Payqr';

function App() {

  return (
    <>

      {
        // user && user.role == 1 &&
        <Router>
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/school" exact component={School} />
          <Route path="/examnation" exact component={Examnation} />
          <Route path="/feedback" exact component={Feedback} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/comlainlist" exact component={ComplainList} />
          <Route path="/notification" exact component={Notification} />
          <Route path="/admission" exact component={Admission} />
          <Route path="/studentlist" exact component={StudentList} />
          <Route path="/applicationdetails" exact component={ApplicationDetails} />
          <Route path="/message" exact component={Message} />
          <Route path="/payment" exact component={Payment} />
          <Route path="/payqr" exact component={Payqr} />

        </Router>
      }

      <Router>
        <Route path="/" exact component={Login} />
      </Router>

    </>

  );
}

export default App;

// // import './App.css';

// // import { BrowserRouter as Router, Route } from "react-router-dom";
// // import Dashboard from './pages/Dashboard';
// // import Login from './pages/Login';
// // import School from './pages/School';
// // import Feedback from './pages/Feedback';
// // import Examnation from './pages/Examnation';
// // import Contact from './pages/Contact';
// // import ComplainList from './pages/ComplainList';
// // import Notification from './pages/Notification';

// // function App() {

// //   const [user, setUser] = useState(null);

// //   const getRole = async () => {
// //     await fetch(setting.api + '/api/getRole', {
// //       method: "GET",
// //       mode: "cors",
// //       headers: {
// //         "Content-Type": "application/json",
// //         "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
// //       },

// //     })
// //       .then(response => response.json())
// //       .then(dd => {
// //         setUser(dd.data);

// //       }
// //       );
// //   }

// //   useEffect(() => {
// //     getRole();
// //   }, []);

// //   return (
// //     <>

// //       {user && user.role == 1 &&
// //         <Router>
// //           <Route path="/dashboard" exact component={Dashboard} />
// //           <Route path="/sections" exact component={Sections} />
// //           <Route path="/subject" exact component={Subject} />
// //           <Route path="/adduser" exact component={AddUser} />
// //           <Route path="/user" exact component={User} />
// //           <Route path="/view/:id" exact component={Viewuser} />
// //           <Route path="/edit/:id" exact component={Edituser} />
// //           <Route path="/class" exact component={Class} />
// //           <Route path="/feesVariable" exact component={FeesVariable} />
// //           <Route path="/academic" exact component={Academic} />
// //           <Route path="/school" exact component={School} />
// //           <Route path="/allfees" exact component={Allfees} />
// //         </Router>
// //       }

// //       <Router>
// //         <Route path="/" exact component={Login} />
// //       </Router>
// //       {user && user.role == 2 &&
// //         <Router>
// //           <Route path="/dashboard" exact component={Dashboard} />
// //           <Route path="/school" exact component={School} />
// //           <Route path="/examnation" exact component={Examnation} />
// //           <Route path="/feedback" exact component={Feedback} />
// //           <Route path="/contact" exact component={Contact} />
// //           <Route path="/comlainlist" exact component={ComplainList} />
// //           <Route path="/notification" exact component={Notification} />

// //         </Router>


// //       }
// //     </>

// //   );
// // }

// // export default App;
