import React, { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import qrcode from "../images/qrcode.jpg";
import { Card, Row, Col, Button } from "react-bootstrap";
const Payqr = () => {

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Payment Details </h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>

                <Form>

                    <Card body>
                        <Card title=""></Card>
                        <Card body id="card">
                            <Row>
                                <Col lg={3}></Col>
                                <Col lg={6}>

                                    <Row>
                                        <Col>
                                            <Image src={qrcode} thumbnail />

                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: 15 }}>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label className="stud"><b>Upload Pay Slip</b></Form.Label>
                                                <Form.Control type="file" onChange={(e) => UploadFile(e)} >
                                                </Form.Control>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button type="Submit" variant="primary" size="sm" style={{ float: "right", marginTop: 15 }}>Upload</Button>
                                </Col>
                                <Col lg={3}>
                                </Col>
                            </Row>
                        </Card>
                    </Card>

                </Form>

            </main >
            <Footer />
        </>
    )
}

export default Payqr;