import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import setting from "../setting.json";

const Feedback = () => {

    const [list, setList] = useState();

    const getAllFeedbacks = async () => {
        await fetch(setting.api + "api/getAllFeedbacks", {
            method: "GET",
            mode: "cors",

        })
            .then(response => response.json())
            .then(c => {
                setList(c.data);

            });
    }

    useEffect(() => {
        getAllFeedbacks();
    })

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Feedback</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>

                <Table striped bordered hover variant="">
                    <thead>
                        <tr>
                            <th>Sr.No.</th>
                            <th>Name</th>
                            <th>Admision No.</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Message</th>

                        </tr>
                    </thead>
                    <tbody>
                        {list && list.map((c, i) =>
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{c.name}</td>
                                <td>{c.adm_no}</td>
                                <td>{c.phone}</td>
                                <td>{c.address}</td>
                                <td>{c.message}</td>

                            </tr>
                        )}

                    </tbody>
                </Table>
            </main>
            <Footer />
        </>
    )
}
export default Feedback;