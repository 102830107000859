const Footer = () => {
    return (

        <footer id="footer" className="footer">
            <div className="copyright">
                &copy;  <strong><span>Devloper</span></strong>. All Rights Reserved
            </div>


        </footer>

    );
}
export default Footer; 