import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card, Row, Col, Button } from "react-bootstrap";
const Message = () => {

    const [show, setShow] = useState(true);

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    {/* <h1>Payment Details </h1> */}
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="dashboard">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>
                <Card body id="card">
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                            <Card>
                                <Row>
                                    <Col lg={12} style={{ backgroundColor: "yellow" }}>
                                        Message

                                    </Col>
                                    <Col lg={12} style={{ backgroundColor: "yellowgreen" }}>
                                        Congratulation

                                    </Col>
                                    <Col className="para" lg={12} >
                                        <p style={{ marginTop: 70 }}>Form successfully Submitted for course name semester for the academic year 2024 -25.  And  Application No. …………...</p>
                                    </Col>
                                </Row>
                            </Card>

                        </Col>
                        <Col lg={3}>
                        </Col>
                    </Row>

                    <div>
                        {
                            show ? <h5></h5> : null
                        }
                        <Link to="/payment">
                            <Button onClick={() => setShow(true)} size="sm">Pay by Cash</Button>
                        </Link>{' '}
                        <Link to="/payqr">
                            <Button onClick={() => setShow(false)} size="sm">Pay by QR</Button>
                        </Link>

                    </div>

                    {/* <Link to="/payment">
                        <Button type="submit" variant="primary" size="sm">Payment Now</Button>
                    </Link> */}
                </Card>


            </main >
            <Footer />
        </>
    )
}
export default Message;