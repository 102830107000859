import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Row, Form, Button, Col, Card, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import setting from '../setting.json';

const schema = yup.object().shape({
    name: yup.string().required("Please enter value"),
    fname: yup.string().required("Please enter value"),
    mname: yup.string().required("Please enter value"),
    dob: yup.date().required("Please enter value"),
    physical: yup.string().required("Please select value"),
    gender: yup.string().required("Please select value"),

    category: yup.string().required("Please select value"),
    relegious: yup.string().required("Please select value"),
    martial: yup.string().required("Please select value"),

    mobile: yup.number().required("Please enter value"),
    adhar_num: yup.number().required("Please enter value"),
    email: yup.string().email().required("Please enter value"),

    address: yup.string().required("Please enter value"),
    state: yup.string().required("Please select value"),
    city: yup.string().required("Please select value"),
    district: yup.string().required("Please enter value"),
    pincode: yup.string().required("Please enter value"),

    course: yup.string().required("Please select value"),
    major_subfirst: yup.string().required("Please select value"),
    major_secund: yup.string().required("Please select value"),
    major_theard: yup.string().required("Please select value"),
    minor_sub: yup.string(),
    vocational_sub: yup.string(),
    tc: yup.string().required("Please select value"),
    highschool: yup.string().required("Please Upload value"),
    inter: yup.string().required("Please upload value"),
    photo: yup.string().required("Please upload value"),
    adhar: yup.string().required("Please upload value"),
    signature: yup.string().required("Please upload value"),

});

const Admission = () => {

    const [add, setAdd] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [coursecList, setCoursecList] = useState([]);
    const [majorList, setMajorList] = useState([]);
    const [minorList, setMinorList] = useState([]);
    const [vo_courseList, setVo_courseList] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const saveApplicant = async (data) => {
        let ss = {
            "name": data.name,
            "fname": data.fname,
            "mname": data.mname,
            "dob": data.dob,
            "physical": data.physical,
            "gender": data.gender,
            "adhar_num": data.adhar_num,
            "mobile": data.mobile,
            "email": data.email,
            "category": data.category,
            "relegious": data.relegious,
            "martial": data.martial,
            "address": data.address,
            "city": data.city,
            "state": data.state,
            "district": data.district,
            "pincode": data.pincode,
            "handicap": data.physical,
            "course": data.course,
            "tc": data.tc,
            "major_subfirst": data.major_subfirst,
            "major_secund": data.major_secund,
            "major_theard": data.major_theard,
            "minor_sub": data.major_sub,
            "vocational_sub": data.vocational_sub,
            "highschool": data.highschool,
            "inter": data.inter,
            "photo": data.photo,
            "Signature": data.Signature,
            "adhar": data.adhar,
        }
        await fetch(setting.api + "/api/saveApplicant",
            {
                body: JSON.stringify(ss),
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then((rec) => {
                reset(formValues => ({
                    ...formValues,
                    name: '',
                    fname: '',
                    mname: '',
                    dob: '',
                    physical: '',
                    gender: '',
                    adhar_num: '',
                    mobile: '',
                    email: '',
                    category: '',
                    relegious: '',
                    martial: '',
                    address: '',
                    city: '',
                    state: '',
                    district: '',
                    pincode: '',
                    handicap: '',
                    course: '',
                    tc: '',
                    major_subfirst: '',
                    major_secund: '',
                    major_secund: '',
                    minor_sub: '',
                    vocational_sub: '',
                    highschool: '',
                    inter: '',
                    photo: '',
                    Signature: '',
                    adhar: '',

                }))

            });
    }



    const getAllVocationalSubject = async () => {
        await fetch(setting.api + "/api/getAllVocationalSubject", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(sd => {
                setVo_courseList(sd.data);
            });
    }

    const getStateList = async () => {
        await fetch(setting.api + "/api/getStateList", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(sd => {
                setStateList(sd.data);
            });
    }

    const getCityByStateId = async (e) => {
        var id = e.target.value;
        await fetch(setting.api + "/api/getCityListByStateId?id=" + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(cm => {
                setCityList(cm.data);
            });
    }

    const getAllMajorSubjectByCourse = async (e) => {

        var id = e.target.value;
        console.log(id);
        await fetch(setting.api + "/api/getAllMajorSubjectByCourse?id=" + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(cm => {
                setMajorList(cm.data);
            });

        await fetch(setting.api + "/api/getAllMinorSubjectByCourse?id=" + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(cm => {
                setMinorList(cm.data);
            });
    }

    const getAllCourse = async () => {
        await fetch(setting.api + "api/getAllCourse", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setCoursecList(u);
            });
    }

    useEffect(() => {
        getAllVocationalSubject();
        getStateList();
        getAllCourse();
    }, []);

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Admission Details </h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>
                <Card className="body">
                    <Card body>
                        <Form onSubmit={handleSubmit(saveApplicant)}>
                            <Row>
                                <Col >
                                    <Form.Group>
                                        <Form.Label className="stud">Applicant Name</Form.Label>
                                        <Form.Control type="text" className="name" name="name" size="sm"
                                            {...register("name")}
                                            id="name"
                                        />
                                        {errors.name && <p>{errors.name.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Father Name</Form.Label>
                                        <Form.Control type="text" size="sm"
                                            name="fname"
                                            {...register("fname")}

                                        />
                                        {errors.fname && <p>{errors.fname.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Mother Name</Form.Label>
                                        <Form.Control type="text" size="sm"
                                            name="mname"
                                            {...register("mname")}

                                        />
                                        {errors.mname && <p>{errors.mname.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Date of Birth</Form.Label>
                                        <Form.Control type="date"
                                            name="stu_dob" size="sm"
                                            {...register("dob")}

                                        />
                                        {errors.dob && <p>{errors.dob.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Physical Handicaped ?</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            {...register("physical")} id="physical" size="sm">
                                            <option>--select--</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>

                                        </Form.Select>
                                        {errors.physical && <p>{errors.physical.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Gender</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            {...register("gender")} id="gender" size="sm"
                                        >
                                            <option>--Select--</option>
                                            <option>Female</option>
                                            <option>Male</option>
                                            <option>Other</option>
                                        </Form.Select>
                                        {errors.gender && <p>{errors.gender.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Category</Form.Label>
                                        <Form.Select aria-label="Default select example" name="category" size="sm"
                                            {...register("category")} id="category"
                                        >
                                            <option value={1}>--Select--</option>
                                            <option value={2}>General</option>
                                            <option value={3}>SC</option>
                                            <option value={4}>ST</option>
                                            <option value={5}>OBC</option>
                                            <option>GEN-EWS</option>
                                        </Form.Select>
                                        {errors.category && <p>{errors.category.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Religion</Form.Label>

                                        <Form.Select aria-label="Default select example" name="stu_relegious" size="sm"
                                            {...register("relegious")} Id="relegious"
                                        >
                                            <option>--Select--</option>
                                            <option value={1}>Hindu</option>
                                            <option value={2}>Muslim</option>
                                            <option value={3}>Sikh</option>
                                            <option value={4}>Christian</option>
                                            <option value={5}>Other</option>
                                        </Form.Select>
                                        {errors.relegious && <p>{errors.relegious.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Martial status</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            {...register("martial")} id="martial" size="sm"
                                        >
                                            <option>--Select--</option>
                                            <option>Married</option>
                                            <option>Unmarried</option>

                                        </Form.Select>
                                        {errors.martial && <p>{errors.martial.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Adhar No.</Form.Label>
                                        <Form.Control type="text" name="stu_adhar_no" size="sm" pattern="[0-9]{12}"
                                            {...register("adhar_num")}
                                        />
                                        {errors.adhar_num && <p>{errors.adhar_num.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* <Card Title>Payment Mode</Card> */}
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Mobile No.</Form.Label>
                                        <Form.Control type="num" name="stu_phone" pattern="[0-9]{10}" size="sm"
                                            {...register("mobile")} id="mobile"
                                        />
                                        {errors.mobile && <p>{errors.mobile.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Email Id.</Form.Label>
                                        <Form.Control type="email" id="email" name="stu_email" size="sm" {...register("email")}
                                        />
                                        {errors.email && <p>{errors.email.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud" >Address</Form.Label>
                                        <Form.Control type="text"
                                            name="address" size="sm"
                                            {...register("address")} id="address"
                                        />
                                        {errors.address && <p>{errors.address.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label >State</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            id='state' {...register('state')}
                                            onChange={(e) => getCityByStateId(e)}
                                        >
                                            <option>select</option>
                                            {
                                                stateList && stateList.map((sm) =>
                                                    <option key={sm.id} value={sm.id} >{sm.state_name}</option>

                                                )
                                            }

                                        </Form.Select>
                                        {errors.state && <p>{errors.state.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>City</Form.Label>
                                        <Form.Select aria-label="Default select example" {...register('city')}>
                                            {
                                                cityList && cityList.map((cm) =>
                                                    <option key={cm.id} value={cm.id}>{cm.city_name}</option>
                                                )
                                            }

                                        </Form.Select>
                                        {errors.city && <p>{errors.city.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud" >District</Form.Label>
                                        <Form.Control type="text" id="district" name="sdistrict" size="sm" {...register("district")}
                                        />
                                        {errors.district && <p>{errors.district.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud" >Pin Code</Form.Label>
                                        <Form.Control type="text" name="stu_permanent_pin" size="sm" pattern="[0-9]{6}"
                                            {...register("pincode")} id="pincode"
                                        />
                                        {errors.pincode && <p>{errors.pincode.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="course">Course</Form.Label>
                                        <Form.Select aria-label="Default select example" {...register("course")}
                                            onChange={(e) => getAllMajorSubjectByCourse(e)}
                                        >
                                            <option >Course</option>
                                            {
                                                coursecList && coursecList.map((a) =>
                                                    <option key={a.brc_id} data={a.exam_base} value={a.brc_id}>{a.course}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {errors.course && <p>{errors.course.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <h6 className="col-lg-3 col-md-4 label" id="major">Major Subject :</h6>

                            <Row>
                                <div className="col-lg-3 col-md-4 label">Subject 1 :</div>
                                <div className="col-lg-9 col-md-8">
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example" {...register('major_subfirst')} size="sm"

                                        >
                                            {
                                                majorList && majorList.map((ms) =>
                                                    <option key={ms.id} value={ms.id}>{ms.subject}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {errors.major_subfirst && <p>{errors.major_subfirst.message}</p>}
                                    </Form.Group>
                                </div>
                            </Row>

                            <Row style={{ marginTop: 10 }}>
                                <div className="col-lg-3 col-md-4 label">Subject 2 :</div>
                                <div className="col-lg-9 col-md-8">
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example" {...register('major_secund')} size="sm"

                                        >
                                            {
                                                majorList && majorList.map((ms) =>
                                                    <option key={ms.id} value={ms.id}>{ms.subject}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {errors.major_secund && <p>{errors.major_secund.message}</p>}
                                    </Form.Group>
                                </div>
                            </Row>

                            <Row style={{ marginTop: 10 }}>
                                <div className="col-lg-3 col-md-4 label">Subject 3 :</div>
                                <div className="col-lg-9 col-md-8">
                                    <Form.Select aria-label="Default select example" {...register('major_theard')} size="sm"

                                    >
                                        {
                                            majorList && majorList.map((ms) =>
                                                <option key={ms.id} value={ms.id}>{ms.subject}</option>
                                            )
                                        }
                                    </Form.Select>
                                    {errors.major_theard && <p>{errors.major_theard.message}</p>}
                                </div>
                            </Row>

                            <h6 className="col-lg-3 col-md-4 label" id="major">Minor Subject :</h6>

                            <Row>
                                <div className="col-lg-3 col-md-4 label">Subject 1 :</div>
                                <div className="col-lg-9 col-md-8">
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example" {...register('minor_sub')} size="sm"

                                        >
                                            {
                                                minorList && minorList.map((ms) =>
                                                    <option key={ms.id} value={ms.id}>{ms.subject}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {/* {errors.minor_sub && <p>{errors.minor_sub.message}</p>} */}
                                    </Form.Group>
                                </div>
                            </Row>

                            <h6 className="col-lg-3 col-md-4 label" id="major" style={{ marginRight: 175 }}>Vocational Course :</h6>

                            <Row style={{ marginTop: 10 }}>
                                <div className="col-lg-3 col-md-4 label"></div>
                                <div className="col-lg-9 col-md-8">
                                    <Form.Group>
                                        <Form.Select aria-label="Default select example" {...register('vocational_sub')} size="sm">
                                            {
                                                vo_courseList && vo_courseList.map((cm) =>
                                                    <option key={cm.id} value={cm.id}>{cm.subject}</option>
                                                )
                                            }
                                        </Form.Select>
                                        {/* {errors.vocational_sub && <p>{errors.vocational_sub.message}</p>} */}
                                    </Form.Group>
                                </div>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud" >TC</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            {...register("tc")} id="tc" size="sm"
                                        >
                                            <option>--Select--</option>
                                            <option>Available</option>
                                            <option>Notavailable</option>

                                        </Form.Select>
                                        {errors.tc && <p>{errors.tc.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">10th</Form.Label>
                                        <Form.Control type="file" onChange={(e) => UploadFile(e)}
                                            {...register("highschool")}>
                                        </Form.Control>
                                        {errors.highschool && <p>{errors.highschool.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">12th</Form.Label>
                                        <Form.Control type="file" onChange={(e) => UploadFile(e)}
                                            {...register("inter")}>
                                        </Form.Control>
                                        {errors.inter && <p>{errors.inter.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Adhar</Form.Label>
                                        <Form.Control type="file" onChange={(e) => UploadFile(e)}   {...register("adhar")}>
                                        </Form.Control>
                                        {errors.adhar && <p>{errors.adhar.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Photo</Form.Label>
                                        <Form.Control type="file" onChange={(e) => UploadFile(e)} {...register("photo")}>
                                        </Form.Control>
                                        {errors.photo && <p>{errors.photo.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="stud">Signature</Form.Label>
                                        <Form.Control type="file" onChange={(e) => UploadFile(e)}
                                            {...register("signature")}>
                                        </Form.Control>
                                        {errors.signature && <p>{errors.signature.message}</p>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Link to="/message">
                                <Button type="Submit" variant="primary" size="sm" style={{ float: "right", marginTop: 15 }}>Submit</Button>
                            </Link>

                        </Form>
                    </Card>

                </Card>
            </main >
            <Footer />
        </>
    );
}

export default Admission;
