import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Table, ListGroup, Button, Form, Row } from "react-bootstrap";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import setting from "../setting.json";
import { useParams } from "react-router-dom";

const schema = yup.object().shape({
    title: yup.string().required("title is required"),
    detail: yup.string().required("detail is required"),
    id: yup.string(),
});

const Notification = () => {

    const [list, setList] = useState([]);
    const [add, setAdd] = useState([]);
    const [data, setData] = useState([]);
    // const { id } = useParams('');
    const [show, setShow] = useState([]);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [updated, setUpdated] = React.useState(false);

    const [idd, setIdd] = useState('');
    const [rec, setRec] = useState('');


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const saveNews = async (data) => {
        let db = {
            "title": data.title,
            "detail": data.detail
        }
        await fetch(setting.api + "api/saveNews", {
            body: JSON.stringify(db),
            method: "post",
            // mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                console.log(u);
                reset({
                    title: '',
                    detail: '',

                });

            });
    }

    const getAllNews = async () => {
        await fetch(setting.api + '/api/getAllNews', {

            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(dd => {
                setList(dd.data);

            }
            );
    }

    const getNewsById = () => {
        var id = event.target.getAttribute("data");
        setIdd('');
        setAdd(false);
        data.filter((a) => {
            if (a.id == id) {
                setIdd(a.id);
                setRec(a);
                reset(formValues => ({
                    ...formValues,
                    title: a.title,
                    detail: a.detail,
                }));
            }
        });
    }

    const updateNews = async (data) => {
        let db = {
            "id": idd,
            "title": data.title,
            "detail": data.detail
        }
        await fetch(setting.api + "/api/updateNews",
            {
                body: JSON.stringify(db),
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(
                response => response.json()
            )

            .then(
                (list) => {
                    getAllNews();
                    reset(formValues => ({
                        ...formValues,
                        title: '',
                        detail: '',
                    }))
                    setAdd(true);
                }
            );


    }


    const deleteNews = async () => {
        var id = event.target.getAttribute("data");
        await fetch(setting.api + '/api/deleteNewsById?id=' + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response)
            .then(a => {
                getAllNews();
                console.log(a);
            }
            );
    }

    useEffect(() => {

        getAllNews();
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);
    useEffect(() => {

        getAllNews();

    }, [updated]);
    useEffect(() => {

        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }



    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Notification </h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>
                <section>
                    <div className="row">
                        <div className="col-xl-5">
                            {add &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title"> Add News  </div>
                                        <Form onSubmit={handleSubmit(saveNews)}>
                                            <Row>
                                                <Form.Group >
                                                    <Form.Label>Title </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="title"
                                                        {...register("title")}
                                                        placeholder='Enter...'
                                                    />
                                                    {errors.title && <p>{errors.title.message}</p>}
                                                </Form.Group>

                                            </Row>
                                            <Row >
                                                <Form.Group>
                                                    <Form.Label>Description </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        id="detail"
                                                        {...register("detail")}
                                                        placeholder='Enter...'


                                                    />
                                                    {errors.detail && <p>{errors.detail.message}</p>}
                                                </Form.Group>
                                            </Row>

                                            <Button type='submit' variant="success" id='sub'>Submit</Button>
                                        </Form>
                                    </div>

                                </div>
                            }

                            {!add &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title"> Update </div>
                                        <Form onSubmit={handleSubmit(updateNews)}>
                                            <Row>
                                                <Form.Group >
                                                    <Form.Label >Title </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter...'
                                                        id="title"
                                                        {...register("title")}

                                                    />
                                                    {errors.title && <p>{errors.title.message}</p>}
                                                </Form.Group>

                                            </Row>

                                            <Row >
                                                <Form.Group >
                                                    <Form.Label >Description </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder='Enter...'
                                                        id='detail'
                                                        {...register("detail")}

                                                    />
                                                    {errors.detail && <p>{errors.detail.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <input type='hidden' id="sid" value={idd} {...register("id")} />
                                            <Button type='submit' variant="success" id='sub' >Update</Button>

                                        </Form>
                                    </div>

                                </div>
                            }

                        </div>

                        <div className="col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title"> Notification List  </div>
                                    <Table striped hover variant="">
                                        <thead>
                                            {!data &&
                                                <tr></tr>
                                            }
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data && data.map((a, i) =>
                                                    <tr key={a.id}>
                                                        <td>{i + 1}</td>
                                                        <td>{a.title}</td>
                                                        <td>{a.detail}</td>
                                                        <td>
                                                            <Button variant='primary' className="bi bi-pencil-square" size="sm" data={a.id} onClick={() => getNewsById()}>
                                                            </Button>  {' '}

                                                            <Button variant='danger' className='ri-delete-bin-line' size='sm' data={a.id} onClick={() => deleteNews()}> </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </Table>
                                    <div className='page' id='sub'>

                                        <Button variant='primary' className='btn' size="sm" onClick={clickFirst}>First</Button>{' '}
                                        <Button variant='primary' className='btn' size="sm" onClick={clickPrev}>Prev</Button>
                                        <span >page {currentPage} of {pageCount}</span>
                                        <Button variant='primary' className='btn' size="sm" onClick={clickNext}>Next</Button>{' '}
                                        <Button variant='primary' className='btn' size="sm" onClick={clickLast}>Last</Button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
export default Notification;