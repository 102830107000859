import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import * as XLSX from 'xlsx';
import { Table, Button, Form, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import setting from "../setting.json";

const schema = yup.object().shape({
    course_id: yup.string().required("Fill value"),
    part_id: yup.string().required("Fill value"),
    acd_year: yup.string().required("Fill value"),

});

const Examnation = () => {


    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [academicList, setAcademicList] = useState();
    const [coursecList, setCoursecList] = useState();
    const [samesterList, setSamesterList] = useState();


    const save = async (data) => {
        let sr = {
            "course_id": data.course_id,
            "part_id": data.part_id,
            "acd_year": data.acd_year,

        }
        await fetch(setting.api + "api/save", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                console.log(u);
                setMessage("Your Request submitted successfully.")
                reset({
                    course_id: '',
                    part_id: '',
                    acd_year: '',

                });

            });
    }


    const getAcademicYear = async () => {
        await fetch(setting.api + "api/getAcademicYear", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setAcademicList(u);

            });
    }

    const getAllCourse = async () => {
        await fetch(setting.api + "api/getAllCourse", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setCoursecList(u);

            });
    }

    const getYearSem = async (e) => {
        var id = e.target.value;
        await fetch(setting.api + "api/getYearSem?cid=" + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setSamesterList(u);

            });
    }

    useEffect(() => {

        getAcademicYear();
        getAllCourse();

    }, []);

    const UploadFile = (e) => {
        e.preventDefault();

        var files = e.target.files, f = files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            console.log("Data>>>" + data);
        };
        reader.readAsBinaryString(f);
    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Examnation </h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>
                <section>
                    <Row>
                        <div className=" col-xl-5 ">
                            <div className="container">
                                <div className="account-wrapper card">

                                    <div className="card-body">
                                        <br />
                                        <Form onScroll={handleSubmit(save)}>

                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Academic Year</Form.Label>
                                                <Form.Select aria-label="Default select example"
                                                    {...register("acd_year")}
                                                >
                                                    <option>Select</option>
                                                    {
                                                        academicList && academicList.map((a) =>
                                                            <option key={a.yr_id} value={a.yr_id}>{a.start_year + '-' + a.end_year}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                                {errors.acd_year && <p>{errors.acd_year.message}</p>}


                                            </Form.Group>

                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Class</Form.Label>
                                                <Form.Select aria-label="Default select example" {...register("course_id")}
                                                    onChange={(e) => getYearSem(e)}
                                                >
                                                    <option>Select</option>
                                                    {
                                                        coursecList && coursecList.map((a) =>
                                                            <option key={a.brc_id} value={a.brc_id}>{a.course}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                                {errors.course_id && <p>{errors.course_id.message}</p>}
                                            </Form.Group>

                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Semestar</Form.Label>
                                                <Form.Select aria-label="Default select example"
                                                    {...register("part_id")}

                                                >
                                                    <option>Select</option>
                                                    {
                                                        samesterList && samesterList.map((a) =>
                                                            <option key={a.base_id} value={a.base_id}>{a.base_name}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                                {errors.part_id && <p>{errors.part_id.message}</p>}
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Upload File</Form.Label>
                                                <Form.Control type="file" onChange={(e) => UploadFile(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                            <br />
                                            <div className="form-group">
                                                <Button type="submit" className="d-block custom-button">
                                                    <span>Submit Now</span>
                                                </Button>
                                            </div>

                                        </Form>

                                    </div>

                                </div>
                            </div>
                        </div >
                        <div className=" col-xl-7 ">
                            <div className="card">
                                <diiv className="card-title"> Student Record</diiv>
                                <Table striped bordered hover variant="">
                                    <thead>
                                        <tr>
                                            <th>Sr.No.</th>
                                            <th>Roll No.</th>
                                            <th>Total Mark</th>
                                            <th>Obtained Mark</th>
                                            <th>%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                            <td>@fat</td>
                                        </tr>

                                    </tbody>
                                </Table>

                            </div>

                        </div >
                    </Row>

                </section >
            </main >
            <Footer />
        </>
    );
}
export default Examnation;
